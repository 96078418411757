export const environment = {
  production: false,
  adminRoot: '/app',
  dashboardUrl: 'http://13.235.27.173',
  subHiddenBreakpoint: 992,
  menuHiddenBreakpoint: 768,
  //defaultColor: 'light.blueyale',
  defaultColor: 'light.greenlime',
  // socketUrl: 'http://localhost:8200/',
  // apiUrl: 'http://localhost:8200/v3',
  // imageUrl: 'http://localhost:8200/',

  //socketUrl: 'https://apistaging.gobuddy.net/',
  //imageUrl: 'https://apistaging.gobuddy.net/',
  //apiUrl: 'https://apistaging.gobuddy.net/v3/',

  socketUrl: 'https://api.gobuddy.net/',
  imageUrl: 'https://api.gobuddy.net/',
  apiUrl: 'https://api.gobuddy.net/v3/',


  fbAppId : '614041657108034',
  isAuthGuardActive: true,
  googleAppId : '839429296373-5dlm14iqdh8978lq6b26f0f68tau31dv.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyAPgZfmhrT6WVkrF6Jan5pqQfVaFIv0pZ0",
    authDomain: "ace-tranquility-358810.firebaseapp.com",
    projectId: "ace-tranquility-358810",
    storageBucket: "ace-tranquility-358810.appspot.com",
    messagingSenderId: "839429296373",
    appId: "1:839429296373:web:5538d15ca7e29273a475bc",
    measurementId: "G-KVER06NK9L"
  }
};
