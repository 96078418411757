import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { exhaustMap, take } from 'rxjs/operators';
import { LangService } from '../shared/lang.service';

@Injectable()
export class ReqInterceptInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,private lang:LangService) { }

  my_headers:HttpHeaders;
  


  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return this.authService.loginSession.pipe(
      take(1),
      exhaustMap(store => {
        if (!store) {
          //console.log(store)
          this.my_headers = new HttpHeaders()
            .append('lang', this.lang.selectedlanguageIndex.toString())
            .append('type', "0")
            .append('caller_type', "2")

          var _modifiedRequest = req.clone({
            headers: this.my_headers,
            body: { ...req.body, ...addBody }
          });
          return next.handle(_modifiedRequest);
        }

        var main_id = req.body && req.body.id ? req.body.id : undefined;;

        if (this.authService.is_main_store_login) {
          this.my_headers = new HttpHeaders().append('servertoken', store.servertoken)
            .append('token', store.servertoken)
            .append('lang', this.lang.selectedlanguageIndex.toString())
            .append('store_id', store._id)
            .append('storeid', store._id)
            .append('type', "0")
            .append('caller_type', "2")
            .append('jwt_token', store.jwt_token || "")

        } else {
          this.my_headers = new HttpHeaders().append('servertoken', store.servertoken)
            .append('token', store.servertoken)
            .append('lang', this.lang.selectedlanguageIndex.toString())
            .append('store_id', store.main_store_id)
            .append('storeid', store.main_store_id)
            .append('id', store._id)
            .append('type', "1")
            .append('jwt_token', store.jwt_token || "")
        }

        var modifiedRequest;

        if (req.body instanceof FormData) {
          req.body.append('store_id', store.main_store_id)
          req.body.append('servertoken', store.servertoken)
          req.body.append('timezone', store.timezone)
          req.body.append('jwt_token', store.jwt_token || "")
          modifiedRequest = req.clone({
            headers: this.my_headers
          });
        } else {

          var addBody = {}

          if (req.body && req.body.server_token !== "" && !req.body.server_token) {
            addBody = {
              server_token: store.servertoken,
              timezone: store.timezone,
              id: main_id ? main_id : store.main_store_id
            }
          }

          modifiedRequest = req.clone({
            headers: this.my_headers,
            body: { ...req.body, store_id: store.main_store_id, ...addBody }
          });
        }

        return next.handle(modifiedRequest)
      })
    );

  }


}
