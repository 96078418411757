
export var GET_METHOD = {

  GET_COUNTRY_LIST: '/api/admin/get_country_list',
  GET_BANKS_LIST: '/api/provider/get_bank_codes',
  GET_SERVER_COUNTRY_LIST: '/admin/get_country_list',
  
  DELIVERY_LIST: '/admin/delivery_list',
  GET_WEIGHT_CATEGORY_PRICE_LIST: "/admin/weight_category_price",
}


export var METHODS = {
  GET_STORE_DETAIL: '/admin/get_admin_store_detail',
  SUB_STORE_LOGIN: '/api/store/sub_store_login',
  GET_BILLING_DETAIL: '/api/store/get_billing_detail',
  UPDATE_BILLING_DETAIL: '/api/store/update_billing_detail',
  GET_STRIPE_PRICE: '/api/admin/get_stripe_price_list',
  GET_STRIPE_ADD_CARD_INTENT: '/api/store/get_stripe_add_card_intent',
  GET_CARD_LIST: '/api/store/get_card_list',
  ADD_CARD: '/api/store/add_card',
  CHECK_DOMAIN: '/api/store/check_domain',
  ADD_SUBSCRIPTION: '/api/admin/add_stripe_subscription',
  UPDATE_SUBSCRIPTION: '/api/admin/update_stripe_subscription',
  SELECT_CARD: '/api/store/select_card',
  DELETE_CARD_LIST: '/api/store/delete_card',
  GET_STORE_PAYMENT: '/api/store/get_stripe_invoice_list',
  UPDATE_STORE_SETTING: '/api/store/update_google_key_setting ',
  ADD_SUB_STORE: '/api/store/add_sub_store',
  UPDATE_SUB_STORE: '/api/store/update_sub_store',
  SUB_STORE_LIST: '/api/store/sub_store_list',
  UPDATE_SPECIFICATIONS_NAME: '/api/store/update_specification_name',
  GET_AVERAGE_SALES_DATA: '/api/store/get_average_sales_data',
  GET_DAILY_SALES_DATA: '/api/store/get_daily_sales_data',
  GET_MONTHLY_SALES_DATA: '/api/store/get_monthly_sales_data',
  GET_YEARLY_SALES_DATA: '/api/store/get_yearly_sales_data',
  GET_ORDERS_DATA: '/api/store/get_orders_data',
  UPDATE_SP_NAME: '/api/admin/update_sp_name',
  COPY_ITEMS: '/api/store/copy_items',
  GET_SETTING_DETAIL: '/api/admin/get_setting_detail',
  STORE_GENERATE_OTP: '/api/store/store_generate_otp',
  FORGOT_PASSWORD: '/api/admin/forgot_password',
  CHECK_DETAIL: '/api/admin/check_detail',
  NEW_PASSWORD: '/api/admin/new_password',
  CHECK_REFERRAL: '/api/admin/check_referral',
  GET_CITY_LIST: '/api/admin/get_city_list',
  LAST_FIFTEEN_DAY_ORDER_DETAIL: '/admin/dashboard/last_fifteen_day_order_detail',
  OFFERS_LIST: '/api/store/search_sort_promo_code_list',
  GET_DELIVERY_LIST_FOR_CITY: '/api/admin/get_delivery_list_for_city',
  GET_DOCUMENT_LIST: '/api/admin/get_document_list',
  UPLOAD_DOCUMENT: '/api/admin/upload_document',
  ADD_ITEM_IN_CART: "/api/user/add_item_in_cart",
  GET_DISPATCHER_ORDER_LIST: "/api/store/get_dispatcher_order_list",
  DISCONNECT_GMAIL: "/api/store/disconnect_auth",
  GET_BANK_DETAIL: "/api/admin/get_bank_detail",
  ADD_BANK_DETAIL: "/api/admin/add_bank_detail",
  ADD_STORE_WAREHOUSE: "/api/store/add_store_warehouse",
  ADD_STORE_ADDRESS: "/api/store/add_store_address",
  DELETE_BANK_DETAIL: "/api/admin/delete_bank_detail",
  FORGOT_PASSWORD_VERIFY: "/api/admin/forgot_password_verify",
  GET_ITEM_DETAIL: "/api/get_item_detail",
  GET_COUNTRY_DATA: "/admin/get_country_data",

  GET_STORE_WAREHOUSE: "/api/store/get_store_warehouse_list",
  GET_STORE_WAREHOUSE_DETAIL: "/api/store/get_store_warehouse_detail",
  GET_STORE_ADDRESSES: "/api/store/get_store_address_list",
  GET_STORE_ADDRESS_DETAIL: "/api/store/get_store_address_detail",

  GET_ORDER_CART_INVOICE: "/api/user/get_order_cart_invoice",
  GET_STORE_ORDER_INVOICE: "/api/store/get_order_invoice",
  GET_STORE_ORDER_DETAIL: "/api/store/get_order_detail",
  GET_HISTORY_DETAIL: "/api/store/order_history_detail",
  GET_USER: "/api/store/get_user",
  PAY_ORDER_PAYMENT: "/api/user/pay_order_payment",
  STORE_PAY_ORDER_PAYMENT: "/api/store/pay_order_payment",
  CREATE_ORDER: "/api/user/create_order",
  CREATE_INSTANT_ORDER: "/api/store/create_order",
  STORE_CREATE_ORDER: "/api/store/create_order",
  APPLY_PROMO_CODE: "/api/user/apply_promo_code",
  GET_COUNTRY_PHONE_NUMBER_LENGTH: '/api/store/get_country_phone_number_length',
  STORE_NOTIFY_NEW_ORDER: '/api/store/store_notify_new_order',

  OLD_GET_PRODUCT_LIST: '/api/store/get_product_list',
  GET_PRODUCT_LIST: '/api/store/get_subcategory_list',
  GET_SOECIFICATION_LIST: '/api/store/get_specification_lists',
  ADD_ITEM: '/api/store/add_item',
  UPLOAD_ITEM_IMAGE: '/api/store/upload_item_image',
  DAILY_EARNING: '/api/store/daily_earning',
  DELIVERY_LIST_SEARCH_SORT: '/api/store/delivery_list_search_sort',
  STORE_CANCEL_OR_REJECT_ORDER: '/api/store/store_cancel_or_reject_order',
  CANCEL_REQUEST: '/api/store/cancel_request',
  CREATE_REQUEST: '/api/store/create_request',
  GET_VEHICLE_LIST: '/api/store/get_vehicle_list',
  GET_ACTIVE_VEHICLE_LIST: '/api/store/get_vehicles_list',
  STORE_COMPLETE_ORDER: "/api/store/complete_order",
  ORDER_PAYMENT_STATUS_SET_ON_CASH_ON_DELIVERY: '/api/store/order_payment_status_set_on_cash_on_delivery',
  GET_STORE_EARNING: '/api/store/get_store_earning',
  GET_ITEM_DATA: '/api/store/get_item_data',
  GET_SPECIFICATION_LISTS: '/api/store/get_specification_lists',
  UPDATE_ITEM: '/api/store/update_item',
  CHANGE_DELIVERY_ADDRESS: '/api/user/change_delivery_address',
  STORE_CHANGE_DELIVERY_ADDRESS: '/api/store/store_change_delivery_address',
  UPDATE_ITEM_IMAGE: '/api/store/update_item_image',
  DELETE_ITEM_IMAGE: '/api/store/delete_item_image',
  GET_PRODUCT_DATA: '/api/store/get_product_data',
  ADD_PRODUCT_GROUP_DATA: '/api/store/add_product_group_data',
  GET_PRODUCT_GROUP_LIST: '/api/store/get_product_group_list',
  GET_PRODUCT_LIST_OF_GROUP: '/api/store/get_group_list_of_group',
  UPDATE_PRODUCT_GROUP_DATA: '/api/store/update_product_group',
  DELETE_PRODUCT_GROUP_DATA: '/api/store/delete_product_group',
  GET_PRODUCT_GROUP_DATA: '/api/store/get_product_group_data',
  UPDATE_PRODUCT: '/api/store/update_product',
  HISTORY: '/api/store/history',
  GET_STORE_PRODUCT_ITEM_LIST: '/api/store/get_store_product_item_list',
  IS_ITEM_IN_STOCK: '/api/store/is_item_in_stock',
  ORDER_LIST_SEARCH_SORT: '/api/store/order_list_search_sort',
  SET_ORDER_STATUS: '/api/store/set_order_status',
  CREATE_MANUAL_ORDER: '/api/user/create_manual_order',
  CHECK_BALANCE_MANUAL_ORDER: '/api/store/check_balance_manual_order',
  GET_ORDER_DATA: '/api/store/get_order_data',
  ADD_PRODUCT: '/api/store/add_product',
  GET_STORE_DATA: '/api/store/get_store_data',
  UPDATE_TRADESAFE_TOKEN: 'api/store/update_tradesafe_token',
  UPDATE: '/api/store/update',
  UPDATE_STORE: '/admin/update_store',
  OTP_VERIFICATION: '/api/store/otp_verification',
  RESEND_OTP_VERIFICATION: '/api/store/resend_otp_verification',
  UPDATE_STORE_TIME: '/api/store/update_store_time',
  GET_SPECIFICATION_GROUP: '/api/store/get_specification_group',
  ADD_SPECIFICATION_GROUP: '/api/store/add_specification_group',
  DELETE_ITEM: '/api/store/delete_item',
  DELETE_SPECIFICATION_GROUP: '/api/store/delete_specification_group',
  ADD_SPECIFICATION: '/api/store/add_specification',
  DELETE_SPECIFICATION: '/api/store/delete_specification',
  LOGIN: '/api/store/login',
  STORE_AUTO_LOGIN: '/api/store/store_auto_login',
  LOGOUT: '/api/store/logout',
  REGISTER: '/api/store/register',
  PROVIDER_LOCATION_TRACK: '/api/store/provider_location_track',
  GET_ORDER_DETAIL: '/api/store/get_order_detail',
  STORE_UPDATE_ORDER: '/api/store/update_order',
  WEEKLY_EARNING: '/api/store/weekly_earning',
  STORE_RATING_TO_USER: "api/store/rating_to_user",
  STORE_RATING_TO_PROVIDER: "api/store/rating_to_provider",
  
  GET_IMAGE_SETTING: '/api/admin/get_image_setting',
  UPDATE_SEQUENCE_NMBER: '/api/store/update_sequence_number',
  COMPLETE_REQUEST: '/api/provider/complete_request',
  COMPLETE_ORDER: '/api/store/complete_order',

  USER_LIST_SEARCH_SORT: '/admin/user_list_search_sort',
  APPROVE_DECLINE_USER: '/admin/approve_decline_user',
  USER_ORDER_HISTORY: '/api/user/order_history',

  ADD_PROMO:'/api/store/add_promo',
  UPDATE_PROMO:'/api/store/update_promo_code',
  UPDATE_PROMO_IMAGE:'/api/store/update_promo_image',
  PROVIDER_LIST_SEARCH_SORT:'/api/provider/provider_list_search_sort',
  PROVIDER_ORDER_LIST:'/api/store/provider_request_history',
  ADD_NEW_STORE_PROVIDER:'/store/add_new_provider',
  UPDATE_STORE_PROVIDER:'/admin/update_provider',
  VIEW_DOCUMENT_LIST: '/admin/view_document_list',
  GET_VEHICLE_TYPE_LIST: '/admin/vehicle_list_for_provider',
  ADD_STORE_PROVIDER_VEHICLE: '/admin/add_vehicle',
  PROVIDER_APPROVE_DECLINE: '/admin/provider_approve_decline',
  GET_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_list',
  UPDATE_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_update',

  SERVICE_LIST: '/admin/service_list',
  GET_VEHICLE_LIST_FOR_DELIVERY_PRICING: '/api/admin/get_vehicle_list',
  ADD_SERVICE_DATA: '/admin/add_service_data',
  UPDATE_SERVICE: '/admin/update_service',
  GET_DELIVERY_TYPE: '/admin/get_delivery_type',

  GET_SETTING_DETAIL_FOR_MAIL_CONFIG: '/api/admin/get_setting_detail_for_mail_config',
  UPDATE_EMAIL_CONFIGURATION: '/admin/update_email_configuration',
  EMAIL_LIST: '/admin/email_list',
  UPDATE_EMAIL: '/admin/update_email',

  GET_SMS_GATEWAY_DETAIL: '/admin/get_sms_gateway_detail',
  SMS_LIST: '/admin/sms_list',
  UPDATE_SMS: '/admin/update_sms',
  UPDATE_SMS_CONFIGURATION: '/admin/update_sms_configuration',

  GET_APP_KEYS: '/api/store/get_app_keys',
  UPDATE_GOOGLE_KEY_SETTING: '/api/store/update_google_key_setting',

  GET_PAYMENT_GATEWAYS: '/api/store/get_payment_gateways',
  UPDATE_CASH_PAYMENT_GATEWAYS: '/api/store/update_cash_payment_gateways',
  UPDATE_PAYMENT_GATEWAYS: '/api/store/update_payment_gateways',
  PAYMENT_GATEWAY_LIST: '/admin/payment_gateway_list',

  UPDATE_IMAGE: '/api/store/update_image',
  ADD_SCRIPT_PAGE: '/api/store/add_script_page',
  SCRIPT_PAGES:'/api/store/script_pages', //store_id
  DELETE_SCRIPT_PAGES: '/api/store/delete_script_pages',

  CHECK_PROMO:'/api/store/check_promo_code',
  GET_PROMO_DETAIL:'/admin/get_promo_detail',
  LIST_EARNING:'/api/store/list_earning',
  LIST_ORDERS:'/api/store/list_orders',
  TABLE_LIST_ORDERS:'/api/store/table_list_orders',
  LIST_REVIEWS:'/api/store/get_reviews_list',
  LIST_ORDERS_HISTORY:'/api/store/list_orders_history',
  FETCH_ORDER_DETAIL:'/api/store/fetch_order_detail',
  LIST_LANGUAGE:'/admin/get_languages',
  GET_SERVICE_DETAIL:'/admin/get_service_detail',
  GET_ZONES:'/admin/get_zone_detail',
  ADD_ZONE:'/admin/add_zone_price',
  UPDATE_ZONE:'/admin/update_zone_price',
  SELECT_DEFAULT_SERVICE:'/admin/select_default_service',
  INVOICE_PAY:'/api/store/invoice_pay',

  INPROGRESS_LIST_ORDERS: '/api/store/order_lists_search_sort',
  COMPLETED_LIST_ORDERS: '/api/store/order_lists_search_sort_complete',
  ORDER_DETAIL_BY_ID: '/api/store/order_by_id',
  RETURNS_GRAPH_DATA: '/api/store/return_data',
  CHANGE_ORDER_CLAIM_STATUS: '/api/store/update_is_claim',
  CHECK_CLAIM_UPDATE: '/api/store/check_calim_update',
  COURIER_DATA_LIST: '/api/store/courier_data_list',

  LATE_DISPATCH_ORDERS: '/api/store/order_lists_late_dispatch',
  LATE_DELIVERY_ORDERS: '/api/store/order_lists_late_delivery',
  CANCELLED_ORDERS_LIST: '/api/store/order_lists_cancelled',
  CLAIM_ORDERS_LIST: '/api/store/insurance_claim_lists',
  EMISSIONS_DATA_LIST: '/api/store/emissions_data_list',
  POTENTIAL_SAVINGS: '/api/store/potential_savings',
  RETURNED_ORDERS_LIST: '/api/store/order_return_list',
  RETURN_ORDER: '/api/store/update_is_return',

  PRIORITY_CHART_DATA: '/api/store/priority_data',
  COURIER_CHART_DATA: '/api/store/courier_data',
  KEY_STATS_DATA: '/api/store/key_stats_data',
  DELIVERIES_DATA: '/api/store/deliveries_data',
  ECOMMERCE_SALES_DATA: '/api/store/ecommerce_sales_data',
  TRAVEL_EMISSIONS_DATA: '/api/store/travel_emissions_data',
  EMISSIONS_SAVINGS_DATA: '/api/store/emissions_savings_data',

  DASHBOARD_TABLE:'/tables',
  CUSTOMER_REVIEW:'/CustomerRev+Rec',
  EARNIG_REPORT:'/EarningReport',
  BUSY_HOUR:'/BusyHour',

  VARIFY_CAPTCHA: '/admin/verify_captcha',
  VERIFY_OTP: '/api/admin/verify_otp',

  FIND_NEAREST_PROVIDER:'/api/store/find_nearest_provider_list',
  RATING_TO_USER: '/api/store/rating_to_user',
  // DASHBOARD_TABLE:'/tables',
  // CUSTOMER_REVIEW:'/CustomerRev+Rec',
  // EARNIG_REPORT:'/EarningReport',
  // BUSY_HOUR:'/BusyHour',

  IMPORT_PRODUCT_ITEM: '/api/store/import_item_product',
  EXPORT_PRODUCT_ITEM: '/api/store/export_item_product',
  IMPORT_MODIFIER: '/api/store/import_modifier_product',
  EXPORT_MODIFIER: '/api/store/export_modifier_product',
  IMPORT_ITEM_IMAGE: '/api/store/import_item_image',
  CHECK_STORE_LOCATION: '/api/store/check_store_location',

  CHECK_COUNTRY_EXISTS: '/api/admin/check_country_exists',
  CHECK_CITY: '/admin/check_city_available',
  GET_COUNTRY_TIMEZONE: '/admin/get_country_timezone',

  ADD_NEW_STORE: '/admin/add_new_store',
  ADD_NEW_USER: '/admin/add_new_user',
  ADD_NEW_PROVIDER: '/admin/add_new_provider',
  ADD_PROVIDER_VEHICLE_DATA: '/admin/add_provider_vehicle_data',

  GET_SERVICE_LIST_BY_CITY: "/admin/get_service_list_by_city",


  FETCH_TABLE_BOOKING_BASIC_SETTING: '/api/store/fetch_table_booking_basic_setting',
  UPDATE_TABLE_BOOKING_BASIC_SETTING: '/api/store/update_table_booking_basic_setting',
  UPDATE_TABLE_BOOKING_TIME_SETTING: '/api/store/update_table_booking_time_setting',
  LIST_TABLE: '/api/store/list_table',
  ADD_TABLE: '/api/store/add_table',
  UPDATE_TABLE: '/api/store/update_table',
  CHANGE_QRCODE: '/api/store/change_qrcode'

}
