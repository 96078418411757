import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService, Store } from '../shared/auth.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class OrderService {

  private _orderChanges = new BehaviorSubject<any>(null);
  _orderObservable = this._orderChanges.asObservable()

  constructor(private helper:Helper,private _auth:AuthService) {}

  list_history(start_date=null,end_date=null,page = 1,perPage = 10,query = {}){
    let json = {start_date,end_date,page,perPage,query}
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LIST_ORDERS_HISTORY, json)
  }


  get_order_detail(orderid){
    let json = {order_id: orderid}
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FETCH_ORDER_DETAIL, json)
  }
  

  list_orders(start_date=null,end_date=null,page = 1,perPage = 10,query = {}){
    let json = {start_date,end_date,page,perPage,query}
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LIST_ORDERS, json)
  }

  inprogress_orders_list(json){
    //let json = {start_date,end_date,page,perPage,search_field,search_value,gb_client_id,gb_client_secret}
    
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.INPROGRESS_LIST_ORDERS, json)
  }
  update_claim_number(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_CLAIM_UPDATE, json)
  }

  completed_orders_list(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.COMPLETED_LIST_ORDERS, json)
  }

  order_detail_by_id(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ORDER_DETAIL_BY_ID, json)
  }
  

  returned_orders_list(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.RETURNED_ORDERS_LIST, json)
  }
  return_order(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.RETURN_ORDER, json)
  }
  change_order_claim_status(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHANGE_ORDER_CLAIM_STATUS, json)
  }

  get_order_lists_late_dispatch(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LATE_DISPATCH_ORDERS, json)
  }

  get_order_lists_late_delivery(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LATE_DELIVERY_ORDERS, json)
  }

  get_order_lists_cancelled(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CANCELLED_ORDERS_LIST, json)
  }
  
  get_claim_orders_lists(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CLAIM_ORDERS_LIST, json)
  }

  get_emissions_data_list(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.EMISSIONS_DATA_LIST, json)
  }

  
  get_potentials_savings(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.POTENTIAL_SAVINGS, json)
  }
  get_priority_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.PRIORITY_CHART_DATA, json)
  }
  get_returns_graph_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.RETURNS_GRAPH_DATA, json)
  }
  
  get_courier_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.COURIER_CHART_DATA, json)
  }
  
  get_key_stats_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.KEY_STATS_DATA, json)
  }

  get_deliveries_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.DELIVERIES_DATA, json)
  }
  
  get_ecommerce_sales_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ECOMMERCE_SALES_DATA, json)
  }
  
  get_travel_emissions_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.TRAVEL_EMISSIONS_DATA, json)
  }

  
  get_emissions_saving_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.EMISSIONS_SAVINGS_DATA, json)
  }
  

  

  
  
  table_list_orders(start_date=null,end_date=null,page = 1,perPage = 10,search_by, search_value){
    let json = {start_date,end_date,page,perPage,search_by, search_value}
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.TABLE_LIST_ORDERS, json)
  }

  set_order_status(order_id,order_status){
    let json = {order_status,order_id}
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.SET_ORDER_STATUS, json).then(()=>{
      this._orderChanges.next({})
    })
  }

  dispatcher_order(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DISPATCHER_ORDER_LIST, {})
  }
  cancel_request(request_id,provider_id){
    let json = {
      provider_id: provider_id,
      request_id: request_id      
    }
    
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CANCEL_REQUEST, json)
  }

  create_manual_order(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CREATE_MANUAL_ORDER, json);
  }
  check_balance_manual_order(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_BALANCE_MANUAL_ORDER, json);
  }

  get_active_vehicle_list(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ACTIVE_VEHICLE_LIST, {})    
  }
  
  get_vehicle_list(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_VEHICLE_LIST, {})    
  }
  
  get_order_data(order_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_ORDER_DETAIL, {order_id})
  }

  create_request(provider_id,order_id,vehicle_id,request_id,estimated_time_for_ready_order = null){
    let json = {
      provider_id: provider_id,
      order_id: order_id,
      vehicle_id: vehicle_id,
      request_id: request_id,      
    }
    if(estimated_time_for_ready_order !== null){
      json['estimated_time_for_ready_order'] = estimated_time_for_ready_order;
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CREATE_REQUEST, json)
  }
  
  find_nearest_provider_list(vehicle_id,order_id){
    var json = { vehicle_id,order_id };
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FIND_NEAREST_PROVIDER, json)
  }

  update_order(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.STORE_UPDATE_ORDER, json).then(data=>{
      this._orderChanges.next({});
      return data;
    })
  }

  complete_order(order_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.COMPLETE_ORDER, {order_id}).then(()=>{
      this._orderChanges.next({})
    })
  }

  reject_order(order_id,cancel_reason, status){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.STORE_CANCEL_OR_REJECT_ORDER, {order_id,cancel_reason,order_status:status}).then(()=>{
      this._orderChanges.next({})
    })
  }


  submit_user_revice(order_id:string,review:string,rating:number){
    var json =  {
      "store_rating_to_user": rating,
      "store_review_to_user": review,
      "order_id": order_id
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.RATING_TO_USER, json).then(()=>{
      this._orderChanges.next({})
    })
   
  }

  

}
